import React, { useMemo, useState, memo, useCallback, useRef } from 'react'
import Form, { ButtonItem, Item, SimpleItem } from 'devextreme-react/form';

const savebuttonOptions = {
    text: 'Speichern!!',
    type: 'success',
    useSubmitBehavior: true
}

export default memo(({ selId, store, onAfterUpdate }) => {
    const [selItm, setSelItm] = useState(null)

    const frm = useRef(null)

    //const frmInst = get(frm, ['current', 'instance'])

    useMemo(() => {

        //console.log('TntDetails::useMemo', selId, store)

        store
            .byKey(selId)
            .then((res) => {
                setSelItm(res)
            })

    }, [selId, store])

    /*
    const regaleRaw = get(selItm, ['regale'], {})
    console.log('%cTntDetails.js line:36 regaleRaw', 'color: #007acc;', regaleRaw)

    const regale = useMemo(() => {
        console.log('%cTntDetails.js line:39 "recalc regale..."', 'color: #007acc;', "recalc regale...");
        return Object.values(regaleRaw)
    }, [regaleRaw])*/

    //const _frmData = frmInst && frmInst.option("formData")
    //console.log('%cTntDetails.js line:35 object', 'color: #007acc;', _frmData)
    //const _name = get(_frmData, 'Name')

    //const t1 = useMemo(() => {
    //    console.log('%cTntDetails.js line:34 object', 'color: #007acc;', _name)
    //    return _name
    //}, [_name])

    const _handleSubmit = useCallback((ev) => {

        const _frmData = frm.current.instance.option("formData")

        store.update(selId, _frmData).then(() => {
            if (onAfterUpdate) {
                onAfterUpdate()
            }
        })

        ev.preventDefault();

    }, [selId, store, onAfterUpdate])

    const _formFieldDataChanged = useCallback((ev) => {
        console.log("_formFieldDataChanged::", ev.dataField, ev.value, ev.component.option("formData"), ev)
        setSelItm(ev.component.option("formData"))
    }, [])

    if (!selItm) {
        return null
    }

    return <div style={{ marginTop: 20 }}>
        <form action="your-action" onSubmit={_handleSubmit}>
            <Form formData={selItm} colCount={2} onFieldDataChanged={_formFieldDataChanged} ref={frm}>
                <SimpleItem dataField="ShortName" editorType="dxTextBox" isRequired={true}>
                </SimpleItem>
                <Item caption="Name" dataField="Name" />
                <Item caption="Plz" dataField="Zip" />
                <Item caption="Straße" dataField="Street" />
                <Item caption="Stadt" dataField="City" />

                <ButtonItem horizontalAlignment="right"
                    buttonOptions={savebuttonOptions}
                />
            </Form>
        </form>
    </div>
})

/*
    <ButtonItem horizontalAlignment="left"
        buttonOptions={doItButtonOptions}
    />
*/