import React, { memo } from 'react'
import TreeList, { RemoteOperations, Column, SearchPanel, Editing, RequiredRule, Paging, Pager, StateStoring, Scrolling, ColumnChooser } from 'devextreme-react/tree-list';


const allowedPageSizes = [5, 10, 20];
const expandedRowKeys = ['4453f842-9605-4e1f-b0e6-14d0941daea3']

/*
const editorLookupData = [
    { id: 'RP', name: 'Razv Prod' },
    { id: 'YW', name: 'Yannick Wippert' },
]*/

const popupOptions = {
    title: 'My Tnt-Data',
    showTitle: true,
    position: { my: 'top', at: 'top', of: window }
}

const formOptions = {
    items: [
        { itemType: "group", caption: "Sys-Info", items: ["ShortName", "Name"] },
        {
            itemType: "tabbed",
            caption: "Info-Area",
            tabs: [
                {
                    title: 'XX', items: [
                        { dataField: "Zip" },
                        { dataField: "Street", caption: "Straße" },
                        { dataField: "City", caption: "Stadt" },
                    ]
                }
            ]
        },
    ]
}

export default memo(({ setSelId, ds }) => {

    return <>
        <TreeList
            id="tenants"
            dataSource={ds}
            rootValue={null}
            defaultExpandedRowKeys={expandedRowKeys}
            showRowLines={true}
            showBorders={true}
            columnAutoWidth={true}
            wordWrapEnabled={true}
            keyExpr="Id"
            parentIdExpr="ParentId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
            selection={{ mode: 'single' }}
            onSelectionChanged={({ selectedRowKeys }) => {
                //console.debug('changing selected id:', selectedRowKeys[0])
                //console.log('%cApp.js line:194 "changing selected id..."', 'color: #007acc;', "changing selected id...");

                setTimeout(() => {
                    setSelId(selectedRowKeys[0])
                }, 10)
            }}
        >
            <Scrolling mode="Virtual" />
            <RemoteOperations filtering={true} sorting={true} grouping={true} />
            <StateStoring enabled={true} type="localStorage" storageKey="tvTenants" />
            <SearchPanel visible={true} />
            <ColumnChooser enabled={true} mode="select" />

            <Editing
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={true}
                useIcons={true}
                mode="popup"
                popup={popupOptions}
                form={formOptions}
            />

            <Paging
                enabled={true}
                defaultPageSize={5} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
                showInfo={true} />

            <Column
                dataField="Name"
                fixed={true}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField="ShortName"
                caption="Kürzel"
            />
            <Column
                dataField="Zip"
                caption="Plz"
            />
            <Column
                dataField="Street"
                caption="Straße"
                visible={false}
            />
            <Column
                dataField="City"
                caption="Stadt"
                visible={false}
            />
        </TreeList>
    </>
})