import React, { useMemo, useState, useCallback } from 'react';
import './App.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

//import Button from 'devextreme-react/button';

import AspNetData from 'devextreme-aspnet-data-nojquery';
//import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import TntDetails from './comps/TntDetails';
import TntList from './TntList';

//const url = 'https://localhost:44389/api/cd9ddc84-89f3-469b-bd83-a36441d7db6c/tenants';
const url = 'https://norisk-api-3m5u5v4eaq-ew.a.run.app/api/cd9ddc84-89f3-469b-bd83-a36441d7db6c/tenants';

/*
const tenantsStore = AspNetData.createStore({
  key: 'Id',
  loadUrl: `${url}`,
  insertUrl: `${url}/InsertTask`,
  updateUrl: `${url}/UpdateTask`,
  deleteUrl: `${url}/DeleteTask`,
  onBeforeSend: function (method, ajaxOptions) {
    //console.log(`onBeforeSend:: ${method}, ${ajaxOptions}`)
    //ajaxOptions.xhrFields = { withCredentials: true };
  }
});
*/

/*
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}
*/
/*
const tntCustStore = new CustomStore({
  key: 'Id',
  mode: 'processed',
  load: function (loadOptions) {
    let params = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary'
    ].forEach(function (i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
    });
    params = params.slice(0, -1);
    return fetch(`${url}${params}`)
      .then(response => response.json())
      .then((data) => {
        return {
          data: data.data,
          totalCount: data.totalCount,
          summary: data.summary,
          groupCount: data.groupCount
        };
      })
      .catch(() => { throw 'Data Loading Error'; });
  },

  update: (key, values) => {
    console.log(`Updating ${key}`, values)

    return {
      Id: key,
      Name: 'Tester',
      ShortName: 'ShortTester',
    }

  },

  onUpdated: (key, values) => {
    console.log(`onUpdated for ${key}`)
  },

});
*/


/*
      <Scrolling mode="standard" />

        <Paging
          enabled={true}
          defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
          showInfo={true} />
*/

function App(props) {
  const [selId, setSelId] = useState(null)

  console.log('App:rrender', selId, props)

  const tntStore = useMemo(() => {

    return AspNetData.createStore({
      key: 'Id',
      loadUrl: `${url}`,
      insertUrl: `${url}`,
      updateUrl: `${url}`,
      deleteUrl: `${url}`,
      onBeforeSend: function (method, ajaxOptions) {
        console.log(`onBeforeSend:: ${method}, ${ajaxOptions}`)
        //ajaxOptions.xhrFields = { withCredentials: true };
      }
    })
  }, [])

  const ds = useMemo(() => {

    return new DataSource({
      store: tntStore
    })

  }, [tntStore])

  const onAfterUpdate = useCallback(
    () => {
      ds.reload()
    }, [ds]
  )

  return (
    <div className="App" style={{ padding: 20 }}>

      <TntList setSelId={setSelId} ds={ds} />

      {selId && <TntDetails key={selId} selId={selId} store={tntStore} onAfterUpdate={onAfterUpdate} />}
    </div>
  );
}

/*
      <Button
        text="Click me"
        onClick={() => {
          console.log('test dx!!')
          /*tntCustStore.push([{
            type: "update", data: {
              Name: 'RN1',
              ShortName: 'RsN1',
            }, key: '5eb404d9-8ff8-4852-a621-d1875dd05339'
          }]);* /

          /*
          tntCustStore.update('5eb404d9-8ff8-4852-a621-d1875dd05339', {
            Name: 'RN1',
            ShortName: 'RsN1',
          })* /
        }}
/>
*/

/*
        <Column
          dataField="Info.Editor"
          editorOptions={_lkpEditorOptions}
        >
          <Lookup dataSource={editorLookupData} valueExpr="id" displayExpr="name" />
        </Column>
*/

export default App